.about {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  padding: 150px 30px;
  box-sizing: border-box;
  margin: 0 auto;
}

.about__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  gap: 45px;
}

.about__title {
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  letter-spacing: 0.005em;
  color: #000000;
  align-self: flex-start;
  margin: 0;
  align-self: flex-start;
}

.about__info-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.about__text {
  position: relative;
  max-width: 978px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 157% */
  letter-spacing: 1.5px;
  color: #000000;
  margin: 0;
}

.about__letter {
  opacity: 0;
  transition: all 0.1s ease-in;
}
.about__letter:hover {
  opacity: 0;
  cursor: pointer;
}
.about__letter_show {
  opacity: 1;
}
.about__letter_animate {
  display: inline-block;
  animation: myAnim 0.5s ease 0s 1 normal forwards;
}

@media screen and (max-width: 1024px) {
  .about__info-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .about__container {
    max-width: 380px;
  }
  .about__text {
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .about {
    padding-left: 19px;
    padding-right: 19px;
  }
  .about__container {
    max-width: 380px;
  }
}
