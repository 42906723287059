.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  padding: 30px;
  box-sizing: border-box;
  margin: 0 auto;
}

.hero__main-container {
  position: relative;
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  gap: 100px;
}

.hero__info-container {
  position: relative;
  margin-top: 119px;
  max-width: 479px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 45px;
  z-index: 2;
}

.hero__subtitle {
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #5e3bee;
  margin: 0;
  align-self: flex-start;
}

.hero__title {
  position: relative;
  font-family: "Roboto-regular";
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 78px;
  /* or 130% */
  color: #333333;
  margin: 0;
}

.hero__tag {
  position: absolute;
  color: #515152;
  font-style: italic;
  font-size: 16px;
  font-family: "la belle", cursive;
  opacity: 0.5;
}

.hero__letter {
  opacity: 0;
  transition: all 0.1s ease-in;
}
.hero__letter:hover {
  opacity: 0;
  cursor: pointer;
}
.hero__letter_show {
  opacity: 1;
}
.hero__letter_animate {
  display: inline-block;
  animation: myAnim 0.5s ease 0s 1 normal forwards;
}

.hero__bottom-container {
  flex-direction: column;
  gap: 32px;
  opacity: 0;
  animation: appear 1s ease 2.5s 1 normal forwards;
}

.hero__buttons-container {
  display: none;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0 24px;
}

.hero__social {
  display: block;
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: linear 0.2s linear;
}
.hero__social_type_gh {
  background-image: url("../../images//hero/gh.png");
}
.hero__social_type_li {
  background-image: url("../../images//hero/linkedin.png");
}
.hero__social:hover {
  opacity: 0.6;
  cursor: pointer;
}

.hero__cv-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
  width: 204px;
  height: 60px;
  background: #5e3bee;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding: 0;
  border: 0;
  transition: opacity 0.2s linear;
}
.hero__cv-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.hero__download {
  text-decoration: none;
}

.hero__background {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 720px;
  height: 649px;
  overflow: visible;
  z-index: 1;
}

.hero__image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__avatar {
  max-width: 550px;
  height: 100%;
  object-fit: cover;
}

.hero__social-container {
  display: flex;
  gap: 20px;
}

@media only screen and (min-width: 1280px) {
  .hero {
    height: 100vh;
  }
}

@media screen and (max-width: 1280px) {
  .hero {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 100%;
    margin: 0;
  }
  .hero__main-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .hero__background {
    position: relative;
  }

  .hero__buttons-container {
    display: flex;
    flex-direction: column;
    gap: 25px 0;
  }
  .hero__background {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .hero {
    padding: 19px 19px;
    background-attachment: scroll;
    background-size: 100% 100%;
    padding-top: 150px;
    max-width: 100%;
  }
  .hero__info-container {
    margin: 0;
  }
}

@media screen and (max-width: 540px) {
  .hero__title {
    font-size: 34px;
    line-height: 44px;
  }
}

@keyframes myAnim {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: rotateX(100deg);
    transform-origin: bottom;
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
    transform-origin: bottom;
  }
}
