.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.navigation__list {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation__item {
  width: 100%;
  overflow: hidden;
}

.navigation__link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  padding: 15px;
  border-radius: 12px;
  box-sizing: border-box;
  transition: opacity 0.2s linear;
}
.navigation__link:hover {
  opacity: 0.6;
  cursor: pointer;
  background-color: #7d5efa;
}

.navigation__icon {
  display: block;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.navigation__icon_type_home {
  background-image: url("../../images/navigation/home.png");
}
.navigation__icon_type_about {
  background-image: url("../../images/navigation/about.png");
}
.navigation__icon_type_skills {
  background-image: url("../../images/navigation/code.png");
}
.navigation__icon_type_work {
  background-image: url("../../images/navigation/work.png");
}
.navigation__icon_type_contact {
  background-image: url("../../images/navigation/contact.png");
}

.navigation__link-lable {
  display: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #ffff;
}
.navigation__link-lable_show {
  display: block;
}
.navigation__link-lable:hover {
  cursor: pointer;
}

@media screen and (max-width: 990px) {
  .navigation__link-lable {
    display: block;
  }
}
