.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  gap: 60px 0;
  padding: 150px 30px;
  padding-top: 0;
  box-sizing: border-box;
  background-color: #f9f9fc;
  margin: 0 auto;
}

.skills__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  gap: 45px;
}

.skills__title {
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  /* ientical to box height, or 127% */
  letter-spacing: 0.005em;
  color: #000000;
  margin: 0;
}

.skills__text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */
  text-align: center;
  letter-spacing: 0.5px;
  color: rgba(18, 18, 18, 0.7);
}

.skills__list {
  display: grid;
  width: 100%;
  justify-content: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(150px, 170px));
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 10px;
}

.skills__skill-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px 0;
  background: #ffffff;
  box-shadow: -5px 5px 5px -5px #00000066;
  padding: 15px 54px;
  box-sizing: border-box;
  opacity: 0;
}
.skills__skill-container_show {
  opacity: 1;
  animation: skill 1s ease 0s 1 normal forwards;
}

.skills__skill-icon {
  width: 61px;
  height: 59px;
  object-fit: cover;
  object-position: center;
}

.skills__skill-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 0.5px;

  color: #000000;
}

.skills__letter {
  opacity: 0;
  transition: all 0.1s ease-in;
}
.skills__letter:hover {
  opacity: 0;
  cursor: pointer;
}
.skills__letter_show {
  opacity: 1;
}
.skills__letter_animate {
  display: inline-block;
  animation: myAnim 0.5s ease 0s 1 normal forwards;
}

@media screen and (max-width: 1024px) {
  .skills {
    position: relative;
  }
}

@media screen and (max-width: 500px) {
  .skills__list {
    justify-content: center;
  }
}

@keyframes skill {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateY(-250px);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateY(-65px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateY(-28px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateY(-8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}
