.footer {
  width: 100%;
  background-color: #f9f9fc;
}

.footer__container {
  width: 100%;
  max-width: 1340px;
  background: #333333;
  border-radius: 30px 30px 0px 0px;
  margin: 0 auto;
  padding: 60px;
  box-sizing: border-box;
}

.footer__upper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #404040;
  padding-bottom: 75px;
  box-sizing: border-box;
}

.footer__left-side {
  max-width: 374px;
}

.footer__logo-container {
  display: flex;
  align-items: center;
  gap: 23px 23px;
}

.footer__logo {
  width: 34px;
  height: 34px;
}

.footer__name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 28.6667px;
  line-height: 43px;
  /* identical to box height */
  color: #ffffff;
  margin: 0;
}

.footer__text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */
  letter-spacing: 0.5px;
  color: #ffffff;
}

.footer__right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px 0;
}

.footer__contact-container {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 27px 0;
  margin: 0;
  padding: 0;
}

.footer__contact {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.5px;
  color: #ffffff;
}

.footer__contact-icon {
  display: block;
  width: 20px;
  height: 20px;
}

.footer__contact-icon_type_email {
  background-image: url("../../images/footer/email.png");
}
.footer__contact-icon_type_phone {
  background-image: url("../../images/footer/phone.png");
}

.footer__social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 25px;
  list-style: none;
  margin: 0;
  padding: 0;
  align-self: flex-start;
}

.footer__social-link-icon-wrap {
  text-decoration: none;
}

.footer__social-icon {
  display: block;
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.2s linear;
}
.footer__social-icon:hover {
  opacity: 0.6;
}
.footer__social-icon_type_li {
  background-image: url("../../images/footer/li.png");
}
.footer__social-icon_type_gh {
  background-image: url("../../images/footer/gh.png");
}

.footer__copyrights {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;

  color: rgba(255, 255, 255, 0.6);
  margin: 0;
}

@media screen and (max-width: 738px) {
  .footer__container {
    display: flex;
    flex-direction: column;
    gap: 60px 0;
    padding: 72px 30px;
  }
  .footer__upper {
    flex-direction: column;
    gap: 60px 0;
  }
  .footer__left-side {
    align-self: flex-start;
  }
  .footer__right-side {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    align-self: flex-start;
  }
}
