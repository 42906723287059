.slide-show {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9fc;
}

.slide-show__slider {
  width: 100%;
  height: 100%;
  box-shadow: -5px 5px 5px #00000066;
  background-color: rgba(0, 0, 0, 0.4);
}

.slide-show__img {
  object-fit: contain;
  object-position: center center;
}

@media screen and (max-width: 500px) {
  .slide-show__img {
    width: 100%;
  }
}
