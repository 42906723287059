.side-bar {
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: 100vh;
  background: #5e3bee;
  overflow: hidden;
  padding: 30px 0;
  box-sizing: border-box;
  transition: all 0.3s linear;
}

.side-bar:hover {
  width: 220px;
}

.side-bar__mobile {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
  object-fit: contain;
  object-position: center;
  z-index: 4;
}

.side-bar__logo-container {
  width: 105px;
  height: 105px;
  background: #6745ec;
  border-radius: 50%;
}

.side-bar__logo {
  font-family: "Poppins";
  text-align: center;
  padding: 30px 15px;
  font-size: 3rem;
  letter-spacing: 1px;
  font-weight: 300;
  color: #ffffff;
  margin: 0;
}

@media screen and (max-width: 990px) {
  .side-bar__mobile {
    display: block;
  }

  .side-bar {
    display: none;
  }
}

.side-bar_show {
  position: fixed;
  display: flex;
  width: 220px;
  z-index: 4;
}
