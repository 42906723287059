@font-face {
  font-family: "Circular";
  src: url("circular_std_font-webfont.woff2") format("woff2"),
    url("circular_std_font-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("poppins-regular-webfont.woff2") format("woff2"),
    url("poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-black";
  src: url("roboto-black-webfont.woff2") format("woff2"),
    url("roboto-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-regular";
  src: url("roboto-regular-webfont.woff2") format("woff2"),
    url("roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "la belle";
  src: url("labelleaurore-regular-webfont.woff2") format("woff2"),
    url("labelleaurore-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
