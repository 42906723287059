.hippo {
  position: relative;
  background: transparent;
  width: 440px;
  border: 0;
  cursor: pointer;
  padding: 0;
  opacity: 0;
  animation: hippo 1s ease 2.5s 1 normal forwards;
}

.ear-left-outer,
.ear-right-outer {
  fill: #919191;
}
.ear-left-inner,
.ear-right-inner {
  fill: #6d6d6d;
}
.eye-right-outer,
.eye-left-outer,
.nostril-right-outer,
.nostril-left-outer,
.body {
  fill: #aaaaaa;
}
.eye-right-inner,
.eye-left-inner {
  fill: #ffffff;
}
.nostril-right-inner,
.nostril-left-inner {
  fill: #8c8c8c;
}
.freckle {
  fill: #7c7c7c;
}
.tongue {
  fill: #ff4848;
}
.tooth-left,
.tooth-right {
  fill: #ffffe1;
}

.hippo__buttons-container {
  position: absolute;
  top: 45%;
  right: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0 24px;
  opacity: 0;
  transition: all 0.4s linear;
}
.hippo__buttons-container:hover {
  opacity: 1;
}

.hippo__social {
  display: block;
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: linear 0.2s linear;
}
.hippo__social_type_gh {
  background-image: url("../../images/hero/github-white.png");
}
.hippo__social_type_li {
  background-image: url("../../images/hero/linkedin-white.png");
}
.hippo__social:hover {
  opacity: 0.6;
  cursor: pointer;
}

.hippo__cv-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
  width: 145px;
  height: 60px;
  background: #5e3bee;
  font-family: Poppins;
  border-radius: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding: 0;
  border: 0;
  transition: opacity 0.2s linear;
}
.hippo__cv-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.hippo__download {
  text-decoration: none;
}

.hippo__social-container {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 1280px) {
  .hippo {
    display: none;
  }
}

@keyframes hippo {
  0% {
    opacity: 0;
    transform: translateX(250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
