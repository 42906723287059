.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 136px;
  box-sizing: border-box;
}

.contact__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1320px;
  padding: 30px;
  padding-top: 150px;
  box-sizing: border-box;
  gap: 45px;
}

.contact__title {
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  letter-spacing: 0.005em;
  color: #000000;
  margin: 0;
}

.contact__form-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}

.contact__form {
  position: relative;
  width: 100%;
  max-width: 536px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  /* Drop Shadow 1 */
}

.contact__input-container {
  position: relative;
  width: 100%;
}

.contact__input-name {
  position: absolute;
  top: -22px;
  left: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 170%;
  /* or 27px */
  /* Secondary */
  color: #4f576a;
  margin: 0;
  background-color: #f9f9fc;
  padding: 10px;
  box-sizing: border-box;
}

.contact__input-bold {
  color: #5e3bee;
}

.contact__input {
  padding: 0 30px;
  display: block;
  font-family: "Poppins";
  font-size: 14px;
  height: 64px;
  width: 100%;
  max-width: 536px;
  color: #000;
  border: 1px solid #585d65;
  resize: none;
  outline: 0;
  border-radius: 30px;
  background: transparent;
  box-sizing: border-box;
}

.contact__input-error {
  color: #db453d;
}

.contact__text {
  padding: 30px;
  display: block;
  font-family: "Poppins";
  font-size: 14px;
  height: 150px;
  width: 100%;
  color: #000;
  border: 1px solid #585d65;
  resize: none;
  outline: 0;
  border-radius: 30px;
  background: transparent;
  box-sizing: border-box;
}

.contact__about-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  width: 100%;
  height: 100%;
  border: 1px solid #585d65;
  resize: none;
  outline: 0;
  border-radius: 30px;
  background: transparent;
  padding: 30px 30px;
  box-sizing: border-box;
}

.contact__about-title {
  font-family: "Poppins";
  font-size: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  margin: 0;
}

.contact__about-info-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.contact__about-info-name {
  font-family: "Poppins";
  font-weight: bold;
  color: #5e3bee;
  margin: 0;
}

.contact__about-info-text {
  font-family: "Poppins";
  color: #7a7a7a;
}

.contact__submit {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  width: 209.33px;
  height: 55px;
  /* Primary */
  background: #5e3bee;
  border-radius: 40px;
  border: 0;
  color: #ffffff;
  transition: opacity 0.2s linear;
}
.contact__submit:hover {
  opacity: 0.6;
  cursor: pointer;
}
.contact__submit_inactive {
  background: #e6e8eb;
  pointer-events: none;
  color: #b6bcbf;
}

@media screen and (max-width: 600px) {
  .contact__form-container {
    flex-direction: column;
  }
}
