.projects {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  padding: 150px 30px;
  background: #f9f9fc;
  margin: 0 auto;
}

.projects__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  gap: 45px;
}

.projects__title {
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  letter-spacing: 0.005em;
  color: #000000;
  margin: 0;
}

.projects__title_type_dark {
  color: #fff;
}

.projects__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 583px));
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

.projects__project {
  position: relative;
  height: 360px;
  width: 100%;
  transition: 0.4s ease-out;
  max-height: 480px;
  z-index: 2;
  box-shadow: -5px 5px 5px #00000066;
  opacity: 1;
}

.projects__info-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  opacity: 0;
  transition: 0.5s;
}

.projects__info-cover::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f9f9fc;
  opacity: 0.99;
  z-index: -1;
  transition: opacity 0.5s linear;
}
.projects__info-cover:hover {
  opacity: 1;
}

.projects__info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px 0;
}

.projects__name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #1b242f;
  margin: 0;
}

.projects__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.projects__text-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
}

.projects__text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #5e3bee;
  margin: 0;
}

.projects__links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.projects__link {
  align-self: flex-start;
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-size: 0.875em;
  letter-spacing: 3px;
  padding: 12px 20px;
  background-color: transparent;
  transition: all 0.3s;
  border: 1px solid #5e3bee;
  transition: opacity 0.2s linear;
  color: #1b242f;
}
.projects__link:hover {
  background-color: #5e3bee;
  cursor: pointer;
}

.projects__link-url {
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.projects__link-url:hover {
  color: #fff;
}

.projects__letter {
  opacity: 0;
  transition: all 0.1s ease-in;
}
.projects__letter:hover {
  opacity: 0;
  cursor: pointer;
}
.projects__letter_show {
  opacity: 1;
}
.projects__letter_animate {
  display: inline-block;
  animation: myAnim 0.5s ease 0s 1 normal forwards;
}

/* Media Queries */

/* projects__number*/
@media screen and (max-width: 768px) {
  .projects__project {
    height: 284px;
  }

  .projects__container {
    gap: 30px 0;
  }

  .projects__number {
    font-size: 5em;
    top: -30px;
    right: -30px;
  }
  .projects__number_type_even {
    top: -30px;
    left: -30px;
  }

  .projects__name {
    font-size: 1.5em;
  }

  .projects__text {
    font-size: 1em;
  }
}

@media screen and (max-width: 425px) {
  .projects {
    padding: 0px 10px 64px 10px;
  }

  .projects__project {
    width: 100%;
    height: 284px;
  }

  .projects__info {
    gap: 10px 0;
    left: 15px;
    bottom: 15px;
  }

  .projects__name {
    font-size: 1em;
  }

  .projects__text {
    font-size: 0.8em;
  }

  .projects__link {
    font-size: 0.2;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.5);
    transform-origin: 50% 0%;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
