.App {
  position: relative;
  display: flex;

  font-family: Poppins, Arial, sans-serif;
  width: 100%;
  background-color: #f9f9fc;
  /* font smoothing */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* text size adjust to maintain proportionality */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;

  /* apply kerning/ligatures to improve legibility (slows performance) */
  text-rendering: optimizeLegibility;
}
